
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body{
  /* font-family: "Open Sans", sans-serif ; */


  font-family: "Poppins", sans-serif;

}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #D2CEC1; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}



.barlowCondensed{
  font-family: "Barlow Condensed", sans-serif;
}


.pswp__img{
  object-fit: cover !important;
}

.swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: calc((100% - 30px) / 2) !important;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }




  @layer components {
    
    .responsive-65 {
      @apply text-32 lg:text-65
    }

    .responsive-65-50 {
      @apply text-35 lg:text-65 leading-[2.7rem] lg:leading-[4rem]
    }


    .responsive-65-50 {
      @apply text-35 lg:text-65 leading-[2.7rem] lg:leading-[4rem]
    }

    .responsive-70-50 {
      @apply text-35 lg:text-70 leading-[2.7rem] lg:leading-[4rem]
    }



    .responsive-50-35 {
      @apply text-32 lg:text-50 leading-[2.7rem] lg:leading-[2rem]
    }
  }
  
  













  @keyframes modal-video {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  @keyframes modal-video-inner {
    from {
      transform: translate(0, 100px);
    }
  
    to {
      transform: translate(0, 0);
    }
  }
  
  .modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: .3s;
    animation-name: modal-video;
    -webkit-transition: opacity .3s ease-out;
    -moz-transition: opacity .3s ease-out;
    -ms-transition: opacity .3s ease-out;
    -o-transition: opacity .3s ease-out;
    transition: opacity .3s ease-out;
  }
  
  .modal-video-effect-exit {
    opacity: 0;
  }
  
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px);
  }
  
  .modal-video-body {
    max-width: 960px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
  }
  
  .modal-video-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box;
    }
  }
  
  .modal-video-movie-wrap {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    background-color: #333;
    animation-timing-function: ease-out;
    animation-duration: .3s;
    animation-name: modal-video-inner;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: -webkit-transform .3s ease-out;
    -moz-transition: -moz-transform .3s ease-out;
    -ms-transition: -ms-transform .3s ease-out;
    -o-transition: -o-transform .3s ease-out;
    transition: transform .3s ease-out;
  }
  
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .modal-video-close-btn {
    position: absolute;
    z-index: 2;
    top: -45px;
    right: 0px;
    display: inline-block;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border: none;
    background: transparent;
  }
  
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px;
    }
  }
  
  .modal-video-close-btn:before {
    transform: rotate(45deg);
  }
  
  .modal-video-close-btn:after {
    transform: rotate(-45deg);
  }
  
  .modal-video-close-btn:before,
  .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px;
  }
  


  


